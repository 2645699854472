import { defineComponent, inject, computed } from 'vue';
import PointsInfo from '@/components/PointsInfo.vue';
import Redeem from '@/components/Redeem.vue';
import ExchangeBoxGroup from '@/components/ExchangeBoxGroup.vue';
export default defineComponent({
    components: { Redeem: Redeem, ExchangeBoxGroup: ExchangeBoxGroup, PointsInfo: PointsInfo },
    setup: function () {
        var exchangeStore = inject('exchangeStore');
        return {
            selectedRedeemType: computed(function () { return exchangeStore.state.redeemBox.selectedRedeemType; }),
        };
    },
});
